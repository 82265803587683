//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TriangleCustomRate from '$resources/images/triangle-custom-rate.png';

export default {
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE').format(Number(value).toFixed(2));
    }
  },
  props: {
    selected: Boolean,
    offer: Object,
    hiredPower: String,
    variant: {
      type: String,
      validator: v => ['common', 'without-wattage'].includes(v),
      default: 'common'
    }
  },
  data: () => ({
    TriangleCustomRate
  }),
  computed: {
    ratesDescription() {
      switch (this.offer.idTarifaPersonalizada) {
        case '65':
        case '68':
          return [
            'La energía honesta.',
            'Te ponemos las cosas fáciles, paga solo por lo que necesitas para tu empresa.',
            'La tarifa más clara que la luz, transparente en los conceptos, con precios justos y honestos que buscan siempre tu ahorro.'
          ];
        case '56':
          return [
            'La energía que nos conecta contigo',
            'La tarifa más clara que la luz, transparente en los conceptos, con precios justos y honestos que buscan siempre tu ahorro.',
            'Únete a Oppidumenergía y empieza a disfrutar de nuestra energía'
          ];
        case '67':
          return [
            'La energía de la buena onda',
            'La tarifa para tu negocio. Solo buen precio, sin permanencia ni obligación de contratar seguros o servicios innecesarios.',
            'Únete a Oppidumenergía y empieza a disfrutar de nuestra energía'
          ];
        default:
          return [];
      }
    },
    tarifaNameFormated() {
      if (this.offer.aliasTarifa) {
        return this.offer.aliasTarifa;
      }

      return this.offer.nombreTarifa;
    },
    priceMontlyClassName() {
      return {
        alone: this.offer.ahorroMensual === null,
        small: this.offer.precioMensual
      };
    },
    savingsMontlyClassName() {
      return {
        small: String(this.offer.ahorroMensual.toFixed(2)).length > 5
      };
    }
  }
};
